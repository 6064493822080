import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
import InfoBox from '../components/info-box';
import SupporterLogos from '../components/supporter-logos';
let config = require('../../cc-config.json');
export const Login = () => {
    const { isLoading, login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(undefined);
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setError(undefined);
        login({ username, password }, setError);
    }, [username, password]);
    const msgText = `Welcome to Colouring ${config.cityName}. You're one of the first people to use the site!`;
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, "Log in"),
            React.createElement(InfoBox, { msg: msgText },
                React.createElement("br", null),
                "Please ",
                React.createElement("a", { href: "https://discuss.colouring.london/" }, "discuss suggestions for improvements"),
                " and ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-london/issues" }, "report issues or problems"),
                "."),
            React.createElement(ErrorBox, { msg: error }),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("label", { htmlFor: "username" }, "Username*"),
                React.createElement("input", { name: "username", id: "username", className: "form-control", type: "text", value: username, onChange: e => setUsername(e.target.value), placeholder: "please note the user name you choose will be public", required: true }),
                React.createElement("label", { htmlFor: "password" }, "Password"),
                React.createElement("input", { name: "password", id: "password", className: "form-control", type: showPassword ? 'text' : 'password', value: password, onChange: e => setPassword(e.target.value), required: true }),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "show_password", name: "show_password", className: "form-check-input", type: "checkbox", checked: showPassword, onChange: e => setShowPassword(e.target.checked) }),
                    React.createElement("label", { htmlFor: "show_password", className: "form-check-label" }, "Show password?")),
                React.createElement(Link, { to: "/forgotten-password.html" }, "Forgotten password?"),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement("input", { type: "submit", disabled: isLoading, value: "Log In", className: "btn btn-primary" }),
                    isLoading && React.createElement("span", null,
                        React.createElement(SpinnerIcon, null),
                        "Logging in...")),
                "Would you like to create an account instead?",
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement(Link, { to: "sign-up.html", className: "btn btn-outline-dark" }, "Sign Up")))),
        React.createElement("hr", null),
        React.createElement("section", { className: "main-col" },
            React.createElement(SupporterLogos, null))));
};
